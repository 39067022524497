import { DateTimePicker as MantineDateTimePicker } from '@mantine/dates';
import { useController } from 'react-hook-form';
import { DateTimePickerProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function DateTimeInput(props: DateTimePickerProps) {
  const { label, name, onChange, value, ...rest } = props;
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  return (
    <MantineDateTimePicker
      id={name}
      label={label}
      valueFormat="DD MMM YYYY HH:mm"
      clearable
      {...rest}
      {...field}
      onChange={(event) => onChange && onChange(event)}
      value={value}
    />
  );
}

export default DateTimeInput;
