import { Group, Text, TextProps, useMantineTheme } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

const ErrorMessage = (props: TextProps & { children?: string }) => {
  const theme = useMantineTheme();
  const { children, ...attr } = props;

  //return null if no error found
  if (!children?.length) {
    return null;
  }
  return (
    <Text size="sm" {...attr}>
      <Group sx={{ gap: '.3rem' }}>
        <IconAlertCircle width={theme.fontSizes.md} />
        {children}
      </Group>
    </Text>
  );
};

export default ErrorMessage;
