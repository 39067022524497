import { Group, Stack, Switch, Text } from '@mantine/core';
import { useController } from 'react-hook-form';
import { SwitchInputProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';
import { Option } from '@/types/Form';

function SwitchInput(props: SwitchInputProps | any) {
  const { label, name, onChange, checked, onLabel, offLabel, disabled = false, ...rest } = props;
  const {
    field,
    fieldState: { error: fieldError },
    formState: { defaultValues },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  const onLabelText = onLabel ?? '';
  const offLabelText = offLabel ?? '';

  return (
    <>
      <Switch
        disabled={disabled}
        name={name}
        label={label}
        id={name}
        onLabel={onLabelText}
        offLabel={offLabelText}
        checked={checked}
        onChange={(event) => onChange && onChange(event.target.value)}
        error={error}
        size="lg"
        {...rest}
      />
    </>
  );
}

export default SwitchInput;
