'use client';

import { TextInput as TextInputController } from '@mantine/core';
import { useController } from 'react-hook-form';
import { TextInputProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

export default function TextInput(props: TextInputProps) {
  const { name, label, ...attr } = props;
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  return <TextInputController id={name} label={label} error={error} {...field} {...attr} />;
}
