import { Select as MantineSelect } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { SelectProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';
import { type UseControllerProps, useController, type FieldValues } from 'react-hook-form';

function Select(props: SelectProps) {
  const { label, options, name, onChange, clearable, defaultState, ...rest } = props;
  
  const {
    field,
    fieldState: { error: fieldError },
    formState: { defaultValues },
  } = useController({ name });

  let canclear = true;
  if (clearable != undefined && clearable != null && (clearable === false || clearable === true)) {
    canclear = clearable;
  }

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  const { onChange: onChangeEvent, ...restField } = field;

  return (
    <MantineSelect
      id={name}
      rightSection={<IconChevronDown width={15} color="black" />}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      label={label}
      onChange={(value) => {
        onChangeEvent(value ?? defaultValues?.[name]);
        if (onChange) {
          onChange(value);
        }
      }}
      clearable={canclear}
      searchable
      error={error}
      {...rest}
      data={options}
      defaultValue={defaultState ? defaultState : ''}
      {...(defaultState ? {} : restField)} 
    />
  );
}

export default Select;
