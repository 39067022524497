import { Textarea as MantineTextarea } from '@mantine/core';
import { useController } from 'react-hook-form';
import { TextareaProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

interface ExtendedTextareaInputProps extends TextareaProps {
  onChangeFunction?: Function;
}

function Textarea(props: ExtendedTextareaInputProps) {
  const { label, name, onChangeFunction, ...rest } = props;
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  const { onChange: onChangeEvent, ...restField } = field;

  return (
    <MantineTextarea
      id={name}
      label={label}
      error={error}
      {...restField}
      onChange={(v) => {
        onChangeEvent(v);
        if (onChangeFunction) {
          onChangeFunction(v.target.value);
        }
      }}
      {...rest}
    />
  );
}

export default Textarea;
