'use client';

import { PasswordInput as PasswordInputController } from '@mantine/core';
import { useController } from 'react-hook-form';
import { PasswordInputProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function PasswordInput(props: PasswordInputProps) {
  const { label, name, ...attr } = props;
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  return <PasswordInputController id={name} label={label} error={error} {...attr} {...field} />;
}

export default PasswordInput;
