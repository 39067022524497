import { useState } from 'react';
import { MultiSelect as MantineMultiSelect } from '@mantine/core';
import { useController } from 'react-hook-form';
import { MultiSelectProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function MultiSelect(props: MultiSelectProps) {
  const { label, name, onChange, ...rest } = props;
  const [options, setOptions] = useState(props.options);
  const {
    field,
    fieldState: { error: fieldError },
    formState: { defaultValues },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  const { onChange: onChangeEvent, ...restField } = field;

  return (
    <MantineMultiSelect
      id={name}
      label={label}
      data={options}
      onChange={(value) => {
        onChangeEvent(value ?? defaultValues?.[name]);
        if (onChange) {
          onChange(value);
        }
      }}
      // onChange={(value) => {
      //   onChange(value ?? defaultValues?.[value]);
      // }}
      error={error}
      getCreateLabel={(query) => `+ ${query}`}
      onCreate={(query) => {
        const capitalized = query.charAt(0).toUpperCase() + query.substring(1);
        const item = { label: capitalized, value: query };
        setOptions((prev) => [...prev, item]);
        return item;
      }}
      searchable
      {...rest}
      {...restField}
    />
  );
}

export default MultiSelect;
