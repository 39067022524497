'use client';

import React from 'react';

import { DefaultControllerProps } from '@/types/Form';
import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import DateInput from './DateInput';
import SwitchGroup from './SwitchGroup';
import NumberInput from './NumberInput';
import Select from './Select';
import MultiSelect from './MultiSelect';
import Textarea from './Textarea';
import TimeInput from './TimeInput';
import DateTimeInput from './DateTimeInput';
import SwitchInput from './Switch';

// use this default controller to render form element based on switch case and props provided
export default function FormController(props: DefaultControllerProps) {
  const { control } = props;
  switch (control) {
    case 'password-input':
      return <PasswordInput {...props} />;
    case 'text-input':
      return <TextInput {...props} />;
    case 'multi-select':
      return <MultiSelect {...props} />;
    case 'date-input':
      return <DateInput {...props} />;
    case 'time-input':
      return <TimeInput {...props} />;
    case 'date-time-input':
      return <DateTimeInput {...props} />;
    case 'switch-group':
      return <SwitchGroup {...props} />;
    case 'number-input':
      return <NumberInput {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'text-area':
      return <Textarea {...props} />;
    case 'switch':
      return <SwitchInput {...props} />;
    default:
      return null;
  }
}
