import { TimeInput as MantineTimeInput } from '@mantine/dates';
import { useController } from 'react-hook-form';
import { TimeInputProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function DatePickerInput(props: TimeInputProps) {
  const { label, name, onChange, value, ...rest } = props;
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;
  return (
    <MantineTimeInput id={name} label={label} error={error} {...rest} {...field} value={value} />
  );
}

export default DatePickerInput;
