import { Group, Stack, Switch, Text } from '@mantine/core';
import { useController } from 'react-hook-form';
import { SwitchGroupProps } from '@/types/Form';
import ErrorMessage from '@/utils/Error';

function SwitchGroup(props: SwitchGroupProps) {
  const {
    label,
    name,
    options,
    orientation = 'horizontal',
    orientationProps,
    onChange,
    checked,
    disabled = false,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error: fieldError },
    formState: { defaultValues },
  } = useController({ name });

  const error = fieldError ? (
    <ErrorMessage>{fieldError.message?.toString()}</ErrorMessage>
  ) : undefined;

  const Orientation = orientation === 'horizontal' ? Group : Stack;

  return (
    <>
      <Text fz="sm" fw={500}>
        {label}
      </Text>
      <Orientation mt="xs" {...orientationProps}>
        {options.map((option, index) => {
          const { label, value, ...rest } = option;
          return (
            <Switch
              disabled={disabled}
              name={name}
              id={name}
              key={`${label}-${index}`}
              value={value}
              checked={checked}
              onChange={(event) => onChange && onChange(event.target.value)}
              error={error}
              size="lg"
              {...rest}
            />
          );
        })}
      </Orientation>
    </>
  );
}

export default SwitchGroup;
